import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { ContentSection, HeaderSection } from "../docs/sections";
import { DocPageMeta, InlineMessage, Header } from "../docs";
import { Code, CodeBlock } from "../docs/docs-code";
import { Paragraph, TextContainer, Ol } from "../../lib/src";

export const Meta: DocPageMeta = {
	category: "Developing",
};

export default (): JSX.Element => {
	return (
		<>
			<HeaderSection
				title="Adding components"
				subTitle="How to add components to FancyLib through FancyLab"
			/>
			<ContentSection>
				<InlineMessage variant="info">
					<Paragraph>
						Please read our{" "}
						<GatsbyLink to="/Developing/contributing">
							process guidelines about contributing
						</GatsbyLink>
						, before starting the process described here.
					</Paragraph>
				</InlineMessage>
				<TextContainer article>
					<Header.H2>Create component in Lab</Header.H2>
					<Paragraph>Only add one component at a time by following these steps:</Paragraph>
					<Ol
						items={[
							<>
								Use the templates in <Code>lab/src/components/_component-template</Code>.
							</>,
							<>
								The <Code>{`<componentname>.examples.tsx`}</Code> file contains the styleguide
								documentation page and has some to-do's that need to be filled out.
							</>,
							<>
								Add your component path to <Code>{`lab/src/index.tsx`}</Code>.
							</>,
						]}
					></Ol>
					<Header.H2>Make component ready for Lib</Header.H2>
					<Paragraph>
						As the champion of a concept, you will attend weekly lab Sessions. In these sessions,
						new components are reviewed together with the Fancy Team. The goal is to stabilise
						components so they can be moved to Lib. There is a Definition of Done to ensure quality
						of component and documentation.
					</Paragraph>
					<Paragraph>
						In essence, lab sessions are collaborative efforts to identify outstanding tasks.
						Examples of outstanding tasks are missing variants, options, change in abstraction,
						styling updates, documentation etc. These tasks will be made either by the Fancy team,
						accessibility experts, UX writers, or the proposing team/champion, depending on the
						nature of the task.
					</Paragraph>
					<Paragraph>
						When there are no more outstanding tasks, the component will be moved to Lib by the
						Fancy team.
					</Paragraph>
					<Header.H2>Definition of Done</Header.H2>

					<CodeBlock language="markdown">
						{`## Notes / comments






# DoD checklist

## Header section
- [ ] Subheading: short description of component.

## Message section
- [ ] Inline info message about champion (only in Lab).
- [ ] Inline warning message about usage of component and relation to other components (when relevant).

## Examples section
- [ ] Short introduction to the component and the showcased examples.
- [ ] Example and short description of default implementation.
- [ ] Examples and short descriptions of most important variants.

## Properties section
- [ ] If it's a component with required events, hook it up so that it exhibits the expected behavior (eg. when you click the close button in a modal, it should close the modal).
- [ ] Descriptions for each property (JSDoc).
- [ ] Implement 'VisualComponent' if the component is a visual component.
- [ ] Implement 'FocusableComponent' If the component is a focusable component.

## Guidelines section
- [ ] Best practices
- [ ] Do not use when
- [ ] Accessibility (when relevant and supplied by Tobias or Mai).
- [ ] Writing (when relevant and supplied by May or George).

## Notable changes section (if applicable)
- [ ] Short explanation of this section.
- [ ] For every note:
  Version number
  Short description of what changed
  Migration steps if applicable

## Documentation rules
- [ ] Always link to other components (FancyLib or FancyPFG) if they are referenced anywhere in the documentation.

## Technical
- [ ] Accessible (Approved by A11y consultant)
- [ ] Browser compatibility testing (manual check in supported browsers)
- [ ] Testing: Try component out in one client outside FancyLib (eg. PFG)
`}
					</CodeBlock>
				</TextContainer>
			</ContentSection>
		</>
	);
};
